<template>
  <div>
    <div class="createText">
      个人信息
    </div>

    <div class="info flex column flex-center">
      <div class="infoUser flex">
        <img class="infoUser-img" :src="detail.avatar" alt="" />
        <div>
          <div class="flex">
            <div class="infoUser-name">{{ detail.name }}</div>
            <div class="infoUser-vip flex flex-center">
              <img
                class="dycontent-vipIcon"
                src="../assets/imgs/vipIcon.png"
                alt=""
              />
              <div>普通会员</div>
            </div>
          </div>
          <!-- <div class="infoUser-integral">900积分</div> -->
        </div>
      </div>

      <div class="infoList flex flex-center">
        <div>
          昵称
        </div>
        <div class="infoList-right">
          {{ detail.name }}
        </div>
      </div>
      <!-- 
      <div class="infoList flex flex-center">
        <div>
          来源
        </div>
        <div class="infoList-right">
          北京路门店
        </div>
      </div> -->

      <div class="infoList flex flex-center">
        <div>
          性别
        </div>
        <div class="infoList-right">
          {{ detail.gender == 1 ? "男" : "女" }}
        </div>
      </div>

      <div class="infoList flex flex-center">
        <div>
          手机号
        </div>
        <div class="infoList-right">
          {{ detail.remark_mobiles? detail.remark_mobiles.join(","):'暂无'}}
        </div>
      </div>
      <!-- 
      <div class="infoList flex flex-center">
        <div>
          邮箱
        </div>
        <div class="infoList-right">
          北京路门店
        </div>
      </div> -->

      <!-- <div class="infoList flex flex-center">
        <div>
          生日
        </div>
        <div class="infoList-right">
          北京路门店
        </div>
      </div> -->

      
      <div class="infoList flex flex-center">
        <div>
          添加时间
        </div>
        <div class="infoList-right">
          {{ detail.createtime }}
        </div>
      </div>

      <div class="infoList flex flex-center">
        <div>
          备注
        </div>
        <div class="infoList-right">
          {{ detail.remark }}
        </div>
      </div>
    </div>

    <!-- 底部固定 -->
    <div class="bottom fixed flex" @click="go()">
      <div class="bottom-add flex-item_center" @click="router('/CreateFollow')">
        返回
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      detail: "",
    };
  },
  async mounted() {
    let _res = await request._getUserDetail({
      id: this.$route.query.id,
    });
    if (_.toInteger(_res.code) === 1) {
      this.detail = _res.data.data;
    }
  },
  methods: {
    go() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.createText {
  font-size: 40px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  padding: 43px 0 36px 30px;
}

.info {
  width: 100%;
  background: #ffffff;
  height: 1315px;
  border-radius: 36px 36px 0px 0px;
}

.infoUser {
  width: 670px;
  padding: 54px 0 39px 30px;
  font-size: 26px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  border-bottom: 1px solid #ededf7;
}

.infoUser-img {
  width: 119px;
  height: 119px;
  border-radius: 50%;
  margin-right: 20px;
}

.infoUser-name {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin-right: 16px;
}

.infoUser-vip {
  width: 137px;
  height: 38px;
  background: #f0f5ff;
  border-radius: 20px 20px 20px 20px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.infoUser-integral {
  margin-top: 26px;
}

.infoList {
  width: 670px;
  padding: 52px 0 26px 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  justify-content: space-between;
  color: #66728f;
  border-bottom: 1px solid #ededf7;
}

.infoList-right {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.dycontent-vipIcon {
  width: 32px;
  height: 29px;
  margin-right: 3px;
}
</style>
